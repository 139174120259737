let teachers = [
  {
    name: 'amanda',
    material: 'Founder',
    img: require('../../../images/teachers/1.jpg'),
  },
  {
    name: 'jessica',
    material: 'Co-Founder',
    img: require('../../../images/teachers/2.jpg'),
  },
  {
    name: 'gustavo',
    material: 'Tax expert',
    img: require('../../../images/teachers/3.jpg'),
  },
  {
    name: 'jack',
    material: 'Insurance expert',
    img: require('../../../images/teachers/4.jpg'),
  },
  {
    name: 'alex',
    material: 'Finance expert',
    img: require('../../../images/teachers/5.jpg'),
  },
];

export { teachers };
