import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Hero.css';
import hero from '../../../images/sections/hero.svg';

class Hero extends Component {
  render() {
    return (
      <div className='hero py-5 mb-5'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='c col-lg-6 col-12'>
              <div className='left-side mb-lg-0 mb-5 text-lg-start text-center'>
                <h3 className='title text-capitalize'>
                  Welcome to <span>Maple Financial</span> !
                </h3>
                <p className='sub-title text-muted text-capitalize'>
                  Strategies tailored specifically for you.
                </p>
                <p className='desc pb-3'>
                  We use our expertise to create a plan to achieve your goals.
                  Putting your best interests first is our passion.
                </p>
                <div className='buttons'>
                  <Link
                    className='btn btn-danger text-capitalize me-3 shadow'
                    to='/about'
                  >
                    read more<i className='ms-2 fas fa-chevron-right'></i>
                  </Link>
                  <Link
                    className='btn btn-outline-danger text-capitalize shadow'
                    to='/contact'
                  >
                    get in touch<i className='ms-2 fas fa-envelope'></i>
                  </Link>
                </div>
              </div>
            </div>

            <div className='c col-lg-6 col-12'>
              <div className='right-side text-lg-end text-center'>
                <img className='w-75 img-fluid' src={hero} alt='hero' />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Hero;
