let navbar_items = [
  {
    name: '',
    icon: 'fas fa-home',
  },
  {
    name: 'about',
    icon: 'fas fa-address-card',
  },
  // {
  //   name: 'blogs',
  //   icon: 'fas fa-blog',
  // },
  // {
  //   name: 'courses',
  //   icon: 'fas fa-book-open',
  // },
  {
    name: 'contact',
    icon: 'fas fa-phone-alt',
  },
];

export { navbar_items };
