let faq_items = [
  {
    id_one: 'headingOne',
    data_bs_target: '#collapseOne',
    aria_controls: 'collapseOne',
    id_two: 'collapseOne',
    aria_labelledby: 'headingOne',
    title: 'What types of insurance policies does your company offer?',
    answerRed:
      'Our company offers a wide range of insurance policies, including life insurance, health insurance, disability insurance, and property insurance.',
    answerNormal:
      'We also offer liability insurance for businesses, as well as specialized policies for specific industries.',
    show: true,
  },
  {
    id_one: 'headingTwo',
    data_bs_target: '#collapseTwo',
    aria_controls: 'collapseTwo',
    id_two: 'collapseTwo',
    aria_labelledby: 'headingTwo',
    title: 'How can I choose the right investment plan for me?',
    answerRed: '',
    answerNormal:
      'Choosing the right investment plan can be a complex process, and we understand that every individual has unique financial goals and needs. Our team of experts will work with you to assess your risk tolerance, investment goals, and time horizon to determine the best investment plan for you.',
    show: false,
  },
  {
    id_one: 'headingThree',
    data_bs_target: '#collapseThree',
    aria_controls: 'collapseThree',
    id_two: 'collapseThree',
    aria_labelledby: 'headingThree',
    title: 'How do I file a claim on my insurance policy?',
    answerRed:
      'Filing a claim on your insurance policy is easy with our company.',
    answerNormal:
      'Simply contact our customer service team. We will guide you through the process and ensure that your claim is processed quickly and efficiently.',
    show: false,
  },
];

export { faq_items };
