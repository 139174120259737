let tes = [
  {
    img: require('../../../images/tes/1.jpg'),
    name: 'Mr. Wang',
    skill: 'Business Owner',
    comment:
      'As a small business owner, I highly recommend Maple Financial. Their personalized attention and tailored solutions have helped me navigate the complex world of insurance and investing. Their expertise has given me confidence in the security of my business, and I appreciate their responsive and proactive approach to addressing my needs.',
  },
  {
    img: require('../../../images/tes/2.jpg'),
    name: 'Mrs. Chen',
    skill: 'Doctor',
    comment:
      'Maple Financial has helped me manage my personal finances and practice with tailored solutions. Their proactive approach and reliability make them a trusted partner for any healthcare professional seeking sound financial advice.',
  },
  {
    img: require('../../../images/tes/3.jpg'),
    name: 'Mrs. Mariano',
    skill: 'Engineer',
    comment:
      "Maple financial's tailored solutions have given me peace of mind for my family's financial future. Their team is responsive, proactive, reliable, and a trustworthy partner for anyone seeking financial security, comprehensive insurance coverage and strategic investment planning.",
  },
];

export { tes };
