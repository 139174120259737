let categories = [
  {
    img: require('../../../images/categories/c1.svg'),
    name: 'Tax and Accounting',
  },
  {
    img: require('../../../images/categories/c2.svg'),
    name: 'Insurance',
  },
  {
    img: require('../../../images/categories/c3.svg'),
    name: 'Investing',
  },
  {
    img: require('../../../images/categories/c4.svg'),
    name: 'Financial Planning',
  },
  //   {
  //     img: require('../../../images/categories/c5.svg'),
  //     name: '',
  //   },
];

export { categories };
