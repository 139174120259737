let leaders = [
  {
    name: 'Mission',
    desc: 'Helping clients prosper and achieve their goals',
  },
  {
    name: 'Vision',
    desc: 'Becoming the top choice when clients think of trustworthy companies',
  },
  {
    name: 'Values',
    desc: 'Client Oriented, Reliability, Accountability, Integrity',
  },
];

export { leaders };
