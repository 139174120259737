let accounts = [
  // {
  //   icon: 'fab fa-facebook',
  //   link: 'https://www.facebook.com/',
  // },
  // {
  //   icon: 'fab fa-twitter',
  //   link: 'https://www.twitter.com/',
  // },
  // {
  //   icon: 'fab fa-instagram',
  //   link: 'https://www.instagram.com/',
  // },
  // {
  //   icon: 'fab fa-youtube',
  //   link: 'https://www.youtube.com/',
  // },
  // {
  //   icon: 'fab fa-linkedin',
  //   link: 'https://www.linkedin.com/',
  // },
];

export { accounts };
